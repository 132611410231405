import React from "react"
import { flash } from "./common"

export default function Counter(props) {
  const [count, setCount] = React.useState(0)
  const textInput = React.useRef(null)
  const handleClick = (val) => {
    setCount(val)
    flash(textInput)
  }
  return <div>
    <div className="field is-horizontal has-addons">
      <div className="control">
        <button className="button is-link" onClick={() => handleClick(count+1)}>+1</button>
      </div>
      <div className="control is-expanded">
        <input ref={textInput} className="input" type="text" value={count} readOnly />
      </div>
      <div className="control">
        <button className="button is-link" onClick={() => handleClick(count-1)}>-1</button>
      </div>
    </div>
  </div>
}
