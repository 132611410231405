import React from "react"
import {nouns} from './nouns'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import { randint, flash } from "./common"

const datalen = nouns.length - 1

function titlecase(x) {
  return x[0].toUpperCase() + x.slice(1)
}

function generate(pattern) {
  var output = []
  pattern.split('').forEach(function (x) {
    switch (x) {
      case "w":
      output.push(nouns[randint(datalen)])
      break
      case "W":
      output.push(titlecase(nouns[randint(datalen)]))
      break
      case "d":
      output.push(randint(9))
      break
      default:
      output.push(x)
    }
  })
  return output.join('')
}

export default function Xkpasswd(props) {
  const [pattern, setPattern] = React.useState(props.pattern)
  const [pwd, setPwd] = React.useState("")
  const textInput = React.useRef(null)
  const handleChange = (event) => {
    setPattern(event.target.value)
  }
  const handleGenerate = () => {
    setPwd(generate(pattern))
    flash(textInput)
  }
  const handleCopy = () => {
    alert(`Copied to clipboard`)
  }
  return <>
    <div className="field has-addons">
      <div className="control is-expanded">
        <input className="input" type="text" placeholder={pattern} onChange={handleChange} />
      </div>
      <div className="control">
        <button className="button is-link" onClick={handleGenerate}>
          Generate
        </button>
      </div>
    </div>
    <div className="field has-addons">
      <div className="control is-expanded">
        <input ref={textInput} className="input" type="text" value={pwd} readOnly />
      </div>
      <div className="control">
        <CopyToClipboard text={pwd} options={{format:'text/plain'}} onCopy={handleCopy}>
          <button className="button is-link">Copy</button>
        </CopyToClipboard>
      </div>
    </div>
  </>
}
