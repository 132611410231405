import React from "react"
import "./mystyles.scss"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Card from "../components/card"
import Xkpasswd from "../components/xkpasswd"
import Dice from "../components/dice"
import Counter from "../components/counter"
import Oracle from "../components/oracle"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />

    <div className="container">
      <div className="columns">

        <div className="column">
          <Card title="xkpasswd">
            <Xkpasswd pattern="W-w-w-dd" />
          </Card>

          <Card title="counter">
            <Counter />
          </Card>
        </div>

        <div className="column">
          <Card title="dice">
            <Dice type={20} />
            <Dice type={10} />
            <Dice type={8} />
            <Dice type={6} />
            <Dice type={4} />
            <Dice type={2} />
            <Dice type={100} />
          </Card>
          <Card title="oracle">
            <Oracle />
          </Card>
        </div>

      </div>
    </div>
  </Layout>
)

export default IndexPage
