import React from "react"

const Card = ({ children, title }) => {
  return (
      <div className="message is-dark">
        <header className="message-header">
          <p>{title}</p>
        </header>
        <div className="message-body">
          {children}
        </div>
      </div>
  )
}

export default Card
