function randint(max) {
  return Math.floor((Math.random() * max) + 1);
}

function flash(el) {
  let grad = ["#ffff3f", "#ffff7e", "#ffffbd", null]
  el.current.style.backgroundColor = "#ffff00"
  for (let i in grad) {
    setTimeout(() => el.current.style.backgroundColor = grad[i], 300 * (Number(i)+1)/grad.length)
  }
}

export { randint, flash }
