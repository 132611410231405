import React from "react"
import { randint, flash } from "./common"

function throwDice(num, max) {
  if (num > 1) {
    let res = []
    for (let i=0; i<num; i++) {
      res.push(randint(max))
    }
    return `${res} = ${res.reduce((a,b) => a+b)}`
  } else {
    return randint(max)
  }
}

export default function Dice(props) {
  const [res, setRes] = React.useState(0)
  const [num, setNum] = React.useState(1)
  const addDice = () => setNum(num+1)
  const removeDice = () => (num > 1) ? setNum(num-1) : null
  const textInput = React.useRef(null)
  const handleClick = () => {
    setRes(throwDice(num, props.type))
    flash(textInput)
  }
  return <div className="field has-addons">
    <div className="control">
      <button className="button is-link" style={{width:'100px'}}
              onClick={handleClick}>
      {num}d{props.type}
      </button>
    </div>
    <div className="control is-expanded">
      <input ref={textInput} className="input" type="text" value={res} readOnly />
    </div>
    <div className="control">
      <button className="button is-link" onClick={addDice}>+</button>
    </div>
    <div className="control">
      <button className="button is-link" onClick={removeDice}>-</button>
    </div>
  </div>
}
