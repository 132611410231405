import React from "react"
import { randint, flash } from "./common"

function ask(adv_dis) {
  let res = null
  let tmpres = []
  if (adv_dis !== 0) {
    tmpres = [
      randint(6),
      randint(6)
    ].sort()
  } else {
    tmpres = [randint(6)]
  }
  if (adv_dis === 1) {
    res = tmpres[1]
  } else {
    res = tmpres[0]
  }
  switch (res) {
    case 1:
      return `[${tmpres}] No, and...`
    case 2:
      return `[${tmpres}] No.`
    case 3:
      return `[${tmpres}] No, but...`
    case 4:
      return `[${tmpres}] Yes, but...`
    case 5:
      return `[${tmpres}] Yes.`
    case 6:
      return `[${tmpres}] Yes, and...`
    default:
      return null
  }
}

export default function Oracle(props) {
  const [res, setRes] = React.useState(0)
  const [adv, setAdv] = React.useState(false)
  const [dis, setDis] = React.useState(false)
  const textInput = React.useRef(null)
  const advBtn = React.useRef(null)
  const disBtn = React.useRef(null)
  const handleClick = () => {
    if (adv) {
      setRes(ask(1))
    } else if (dis) {
      setRes(ask(-1))
    } else {
      setRes(ask(0))
    }
    flash(textInput)
  }
  const handleAdv = () => {
    if (adv === false) {
      advBtn.current.className = "button is-link"
      disBtn.current.className = "button"
      setAdv(true)
      setDis(false)
    } else {
      advBtn.current.className = "button"
      setAdv(false)
    }
  }
  const handleDis = () => {
    if (dis === false) {
      disBtn.current.className = "button is-link"
      advBtn.current.className = "button"
      setDis(true)
      setAdv(false)
    } else {
      disBtn.current.className = "button"
      setDis(false)
    }
  }
  return <div className="field has-addons">
    <div className="control">
      <button className="button is-link" onClick={handleClick}>
      ask
      </button>
    </div>
    <div className="control is-expanded">
      <input ref={textInput} className="input" type="text" value={res} readOnly />
    </div>
    <div className="control">
      <button ref={advBtn} className="button" onClick={handleAdv}>
      adv
      </button>
    </div>
    <div className="control">
      <button ref={disBtn} className="button" onClick={handleDis}>
      dis
      </button>
    </div>
  </div>
}
